.loader {
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    gap: 5px;
  }

.dot {
    height: 5px;
    width: 5px;
    background-color: black;
    border-radius: 50%;
}

/* Animation */
@keyframes blink {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
}

.dot-1 { animation: blink 1.4s infinite; }
.dot-2 { animation: blink 1.4s infinite 0.2s; } /* Start animation with a slight delay */
.dot-3 { animation: blink 1.4s infinite 0.4s; } /* Start animation with more delay */
