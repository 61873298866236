p {
  margin: 0;
}

#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: auto;
}

#app-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 550px;
}

header {
  min-height: 20vh;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  gap: 1vw;
  width: 100%;
}

.sign-out {
  font-weight: 400 !important;
  font-size: 12px !important;
  height: 36px !important;
}

.user-name {
  font-size: 18px;
}

.server-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
}

.server-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.external svg {
  height: 18px;
}

.server-status {
  position: relative;
  font-size: 12px;
  font-weight: bold;
  height: 32px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 20px;
}

:root {
  --color-running: rgb(89, 178, 0);
  --color-running-semi: rgba(89, 178, 0, 0.485);
  --color-stopped: rgb(103, 103, 103);
  --color-stopped-semi: rgb(179, 179, 179);
  --color-danger: rgb(231, 47, 47);
  --color-danger-semi: rgba(231, 47, 47, 0.535);
}

.server-status.running {
  background-color: var(--color-running);
}

.server-status.pending {
  background-color: var(--color-running-semi);
}

.server-status.stopped {
  background-color: var(--color-stopped);
}

.server-status.stopping {
  background-color: var(--color-stopped-semi);
}

.server-status.terminated {
  background-color: var(--color-danger);
}

.server-status.shutting-down {
  background-color: var(--color-danger-semi);
}


.server-name-link {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-weight: bold;
  font-size: 24px;
  align-items: start;
}

.action-buttons {
  display: flex;
  gap: 8px;
}

.action-button {
  border-radius: 1px !important;
  font-size: 12px !important;
  width: 80px !important;
}

.action-button.stopped {
  background-color: rgb(205, 205, 205) !important;
}

.blinking-dot {
  position: absolute;
  left: 14px;
  top: 13px;
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 50%;
  animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.address {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.address .ip {
  font-size: 14px;
  color: grey;
}